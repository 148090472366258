<template>
  <div class="dashboard-v2 artist-container">
    <div class="container-fluid mb-20" v-loading.fullscreen.lock="loading">
      <el-row class="artist-list mt-10 mb-20" :gutter="0">
        <el-col :span="24">
          <div class="d-flex justify-content-between">
            <div class="justify-content-start">
              <h2 class="artist-head-title ms-12">Artist you searched most</h2>
            </div>
            <div class="justify-content-end">
              <button
                class="btn btn-lg btn-primary btn-style"
                @click="showAllSearchedArtists(100)"
              >
                Show all
              </button>
            </div>
          </div>
          <el-row :gutter="24">
            <el-col
              :xl="4"
              :lg="6"
              :md="6"
              :sm="12"
              v-for="(artist, key) in searchedMostArtists"
              v-bind:key="key"
            >
              <el-card
                class="box-card aside-dark"
                @click="routeToNewDesignPage(artist)"
              >
                <div class="text-center">
                  <el-avatar
                    :size="150"
                    :src="
                      artist.imageUrl
                        ? artist.imageUrl
                        : 'media/avatars/blank.png'
                    "
                  ></el-avatar>
                  <h3 class="artist-name truncate">
                    {{ artist.name.toUpperCase() }}
                  </h3>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="artist-list mt-10 mb-10" :gutter="0">
        <el-col :span="24">
          <div class="d-flex justify-content-between">
            <div class="justify-content-start">
              <h2 class="artist-head-title ms-12">Recently Followed</h2>
            </div>
            <div class="justify-content-end">
              <button
                class="btn btn-lg btn-primary btn-style"
                @click="showAllFollowedArtists(100)"
              >
                Show all
              </button>
            </div>
          </div>
          <el-row :gutter="24">
            <el-col
              :xl="4"
              :lg="6"
              :md="6"
              :sm="12"
              v-for="(artist, key) in recentlyFollowedArtists"
              v-bind:key="key"
            >
              <el-card
                class="box-card aside-dark"
                @click="routeToNewDesignPage(artist)"
              >
                <div class="text-center">
                  <el-avatar
                    :size="150"
                    :src="
                      artist.imageUrl
                        ? artist.imageUrl
                        : 'media/avatars/blank.png'
                    "
                  ></el-avatar>
                  <h3 class="artist-name truncate">
                    {{ artist.name.toUpperCase() }}
                  </h3>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import {
  getArtistUUIDBySpotifyID,
  userFollowedArtistsV2,
  userSearchedHistoryV2,
} from "@/api/mongoDB";

export default {
  name: "DashboardV2",
  setup() {
    const loading = ref(false);
    const store = useStore();
    const searchedMostArtists = ref([]);
    const recentlyFollowedArtists = ref([]);
    onMounted(async () => {
      try {
        loading.value = true;
        const { data } = await userSearchedHistoryV2(20);
        searchedMostArtists.value = data;
        const followedArtists = await userFollowedArtistsV2(20);
        recentlyFollowedArtists.value = followedArtists.data;
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    });

    const routeToNewDesignPage = async (artist) => {
      try {
        loading.value = true;
        if (artist.soundchartsUUID) {
          await store.dispatch(
            "ArtistModule/setArtistUUID",
            artist.soundchartsUUID
          );
        } else {
          const { data } = await getArtistUUIDBySpotifyID(artist.spotifyID);
          await store.dispatch("ArtistModule/setArtistUUID", data);
        }
        window.open(`/ai-artist/${artist.spotifyID}`, "_blank");
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const showAllSearchedArtists = async (limit) => {
      try {
        loading.value = true;
        const { data } = await userSearchedHistoryV2(limit);
        searchedMostArtists.value = data;
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const showAllFollowedArtists = async (limit) => {
      try {
        loading.value = true;
        const followedArtists = await userFollowedArtistsV2(limit);
        recentlyFollowedArtists.value = followedArtists.data;
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      searchedMostArtists,
      recentlyFollowedArtists,
      routeToNewDesignPage,
      showAllSearchedArtists,
      showAllFollowedArtists,
    };
  },
};
</script>
<style lang="scss" scoped>
.artist-head-title {
  font-weight: 700;
  color: #33647f;
  font-size: 20px;
}
.artist-name {
  color: #33647f;
}
.box-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
</style>
